const githubIcon = <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
>
    <path
        fill="#24292F"
        d="M12.01 1C5.92 1 1 5.958 1 12.09c0 4.904 3.153 9.053 7.528 10.522.547.11.747-.238.747-.532 0-.257-.018-1.138-.018-2.057-3.063.662-3.7-1.322-3.7-1.322-.492-1.285-1.222-1.616-1.222-1.616-1.002-.679.073-.679.073-.679 1.112.074 1.696 1.139 1.696 1.139.984 1.689 2.57 1.211 3.207.918.092-.717.383-1.212.693-1.488-2.442-.257-5.012-1.212-5.012-5.472 0-1.212.437-2.203 1.13-2.974-.11-.276-.492-1.414.11-2.938 0 0 .929-.294 3.025 1.138a10.585 10.585 0 012.752-.367c.93 0 1.877.128 2.752.367 2.096-1.432 3.026-1.138 3.026-1.138.602 1.524.219 2.662.11 2.938.71.77 1.13 1.762 1.13 2.974 0 4.26-2.57 5.197-5.031 5.472.4.35.747 1.01.747 2.057 0 1.487-.018 2.68-.018 3.048 0 .294.2.643.747.533A11.083 11.083 0 0023 12.09C23.018 5.958 18.079 1 12.01 1z"
    ></path>
</svg>

export default githubIcon;