import Wrapper from "../../wrappers/Transactions";

const Transactions = () => {
  return (
    <Wrapper>
      <h3>Your transactions</h3>
    </Wrapper>
  );
};

export default Transactions;
