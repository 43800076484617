const fb = <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <mask
        id="mask0_537_125"
        style={{ maskType: "luminance" }}
        width="22"
        height="22"
        x="1"
        y="1"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#fff" d="M1 1h22v22H1V1z"></path>
      </mask>
      <g mask="url(#mask0_537_125)">
        <path
          fill="#0866FF"
          d="M23 12c0-6.075-4.925-11-11-11S1 5.925 1 12c0 5.159 3.551 9.487 8.343 10.676v-7.315H7.074V12h2.269v-1.448c0-3.744 1.694-5.48 5.37-5.48.697 0 1.9.137 2.391.273v3.048c-.26-.028-.71-.041-1.27-.041-1.804 0-2.501.683-2.501 2.46V12h3.593l-.618 3.361h-2.975v7.558C18.779 22.262 23 17.624 23 12z"
        ></path>
        <path
          fill="#fff"
          d="M16.308 15.361L16.925 12h-3.593v-1.189c0-1.776.697-2.46 2.501-2.46.56 0 1.011.014 1.27.042V5.345c-.491-.136-1.694-.273-2.39-.273-3.676 0-5.37 1.736-5.37 5.48V12H7.074v3.361h2.269v7.315a11.025 11.025 0 003.99.243v-7.558h2.975z"
        ></path>
      </g>
    </svg>

export default fb;